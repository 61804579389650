<template>
  <div class="lecture_warp">
    <!-- <TopCard ref="TopCardRef"
             text="善利名师讲义"
             :showBack="true" /> -->
    <div class="subject_wrap">
      <subjectCard @initList="initList"
                   ref="subjectCard"
                   :showSearch="true" />
    </div>
    <!-- <div class="
                   subject_search">
        <div class="search">
          <el-input placeholder="请输入讲义名称"
                    v-model="keyword">
            <i class="el-icon-search"
               slot="suffix"
               @click="search">
            </i>
          </el-input>
        </div>
    </div> -->
    <div class="content">

      <div class="categorys">
        <div v-for="item in cateList"
             :class="{'cative':item.lecture_category_id == category_id}"
             @click="check(item)"
             :key="item.lecture_category_id">
          {{item.lecture_category_name}}
        </div>

      </div>

      <div class="right">
        <div class="list">
          <div class="item"
               v-for="(item,index) in list"
               :key="index">
            <div class="name">
              {{item.lecture_name}}
            </div>
            <div class="category_name">
              {{cateList.filter(i=>item.lecture_category_id==i.lecture_category_id)[0]?cateList.filter(i=>item.lecture_category_id==i.lecture_category_id)[0].lecture_category_name:''}}
            </div>
            <div class="btn_wrap">
              <div class="btn"
                   @click="getLectureInfo(item)">
                讲义详情
              </div>
              <div class="btn"
                   @click="doPaper(item)"
                   v-if="item.lecture_questions">
                开始训练
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <pagination ref="pagination"
                      :get-data="getData"
                      :now-page.sync="page"
                      :now-size.sync="size"
                      :total="total" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopCard from '@/views/components/top_card/index.vue'
import subjectCard from '@/views/components/subject_card/index.vue'
import { getHandoutCategory, getList, getInfo } from '@/api/handout.js'
export default {
  data () {
    return {
      tableData: [],
      cateList: [],
      category_id: '',
      keyword: '',
      total: 0,
      page: 1,
      size: 20,
      list: [],
      subject_id: ''
    }
  },
  components: {
    TopCard, subjectCard
  },
  computed: {

  },
  mounted () {
    // this.initList()

    this.$refs.subjectCard.getSubjectList()

  },
  methods: {
    search () {
      this.$refs.pagination.toFirstPage()
    },
    async initList (id, index, keyword) {
      if (keyword) {
        this.keyword = keyword
        this.search()
        return
      }
      const { data } = await getHandoutCategory({
        subject_id: id
      })
      this.subject_id = id
      this.cateList = data
      if (this.cateList.length > 0) {
        this.check(this.cateList[0])
      } else {
        this.list = []
        this.total = 0
      }
    },
    check (row) {
      this.category_id = row.lecture_category_id
      this.search()
    },

    async getData (page, limit) {
      if (!this.category_id) {
        return
      }
      let params = {
        category: this.category_id,
        keyword: this.keyword,
        page,
        limit,
      }
      const { data } = await getList(params)
      this.list = data.volist || []
      this.total = data.count || 0
      this.keyword = ''
    },
    checkSubject (item) {
      this.category_id = item.lecture_category_id
      this.search()
    },
    async getLectureInfo (item) {
      let params = {
        lecture_id: item.lecture_id
      }
      const { data } = await getInfo(params)
      window.open(data.file_url, '_brank')
    },
    doPaper (item) {
      let params = {
        lecture_id: item.lecture_id,
        subject_id: this.subject_id
      }
      this.$router.push('/doPaper?type=7&form=' + JSON.stringify(params))
    }
  }
}
</script>

<style lang="scss" scoped>
.lecture_warp {
  .subject_wrap {
    background: #fff;
    margin-top: -10rem;
  }
  .subject_search {
    display: flex;
    // height: 44rem;
    margin-bottom: 10rem;
    margin-top: 10rem;
    position: relative;
    .search {
      position: absolute;
      width: 31%;
      height: 44rem;
      right: 50rem;
      ::v-deep .el-input--medium .el-input__inner {
        height: 44rem;
        line-height: 44rem;
        border-radius: 27rem;
        font-size: 18rem;
        font-weight: 500;
        color: #999999;
        background: white;
      }
      ::v-deep .el-icon-search {
        margin-right: 20rem;
        font-size: 22rem;
      }
      ::v-deep .el-input__suffix {
        display: flex;
        align-items: center;
      }
    }
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 10rem;
    // overflow-y: auto;
    .item {
      width: 422rem;
      height: 263rem;
      box-shadow: 0px 2px 8px 1px rgba(0, 0, 0, 0.09);
      background: white;

      border-radius: 20rem;
      margin-left: 38rem;
      margin-top: 30rem;
      .name {
        font-size: 26rem;
        font-weight: bold;
        color: #333333;
        margin: 47rem 30rem 12rem;
        min-height: 48rem;
        display: flex;
        justify-content: center;
      }
      .category_name {
        font-size: 23rem;
        // margin-top: 10rem;
        margin-left: 10px;
        font-family: PingFang-SC-Bold-, PingFang-SC-Bold;
        font-weight: normal;
        text-align: center;
        color: #999999;
      }
      .btn_wrap {
        display: flex;
        justify-content: space-between;
        margin-top: 30rem;
        width: calc(100% - 60rem);
        padding: 0 20rem;
        .btn {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 130rem;
          height: 44rem;
          background: #2196f3;
          border-radius: 40rem;
          font-size: 22rem;
          color: #ffffff;
          border-radius: 40rem;
          font-weight: bold;
          &:nth-child(1) {
            background: #e6f0ff;
            margin-left: 10rem;
            color: #2196f3;
          }
          &:nth-child(2) {
            background: #2196f3;
            color: #ffffff;
          }
        }
      }
    }
  }
  .active_subject {
    border-bottom: 4rem solid #1890ff;
    color: #2196f3 !important;
  }
  .content {
    display: flex;
    height: calc(100vh -80rem);
    background: #fff;
    margin-top: -10rem;
    .categorys {
      width: 375rem;
      line-height: 94rem;
      background: #f2f9ff;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      font-size: 30rem;
      // text-align: center;
      & > div {
        padding-left: 38rem;
        // box-shadow: inset 0px -1px 0px 1px rgba(229, 229, 229, 1);
        border-bottom: 1px solid rgba(229, 229, 229, 1);
        cursor: pointer;
      }
      .cative {
        background: #fff;
      }
    }
    .right {
      flex: 1;
      height: calc(100vh - 80rem);
      overflow: auto;
    }
  }
  .pagination {
    margin-top: 10px;
  }
}
</style>